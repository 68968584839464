(function () {
  'use strict';

  angular
    .module('module')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('module', {
        url: '/module/:moduleId',
        templateUrl: 'module/module.tpl.html',
        controller: 'ModuleCtrl',
        controllerAs: 'moduleCtrl',
        data: {
          authenticate: true
        }
      });
  }
}());
